<template lang="pug">
div.container
  b-row.mb-4.text-center(align-h="center")
    h1 Create a New Affiliate

  div.row
    div.col-md-4.d-none.d-md-block
      b-list-group.pb-3
        b-list-group-item(active).d-flex.justify-content-between.align-items-center Membership Details #[fa-icon(:icon="newAffiliate.organisationType === 'Business' ? 'city' : 'graduation-cap'")]
        b-list-group-item.d-flex.justify-content-between.align-items-center Name <span>{{newAffiliate.name}}</span>
        b-list-group-item.d-flex.justify-content-between.align-items-center Starts <span>{{newAffiliate.createdDate | moment("MMMM YYYY")}}</span>
        b-list-group-item.d-flex.justify-content-between.align-items-center Expires <span>{{newAffiliate.validUntil | moment("MMMM YYYY")}}</span>
        b-list-group-item.d-flex.justify-content-between.align-items-center Type #[span {{ newAffiliate.organisationType }}]
        b-list-group-item.d-flex.justify-content-between.align-items-center Member IDs <span>{{newAffiliate.memberIdPrefix}}{{newAffiliate.createdDate | moment("YY")}}-**</span>
      b-list-group.pb-3
        b-list-group-item(active) Contact Information
        b-list-group-item.d-flex.justify-content-between.align-items-center Name <span>{{newAffiliate.contact.name}}</span>
        b-list-group-item.d-flex.justify-content-between.align-items-center Email <span>{{newAffiliate.contact.email}}</span>

    div.col-md-8
      b-form
        b-form-row
          b-form-group.col(label="Company Name *" label-for="name")
            b-input(type="text" name="name" v-model="newAffiliate.name" placeholder="Arrogant Games" required)
          b-form-group.col(label="Member ID Prefix *" label-for="name")
            b-input(type="text" name="name" v-model="newAffiliate.memberIdPrefix" placeholder="ARR" required)
        b-form-row
          b-form-group.col(label="Main Contact Name *" label-for="contactName")
            b-input(type="text" name="contactName" v-model="newAffiliate.contact.name" required)
          b-form-group.col(label="Main Contact Email *" label-for="contactEmail")
            b-input(type="email" name="contactEmail" v-model="newAffiliate.contact.email" required)
        b-form-row
            b-form-group.col(label="Notes" label-for="notes")
              b-form-textarea(name="notes" v-model="newAffiliate.notes" :rows="3" :max-rows="6" placeholder="Special arrangements or other considerations that have been discussed with them?")
        b-form-row
          b-form-group.col(label="Organisation Type *" label-for="type")
              b-form-select(name="type" v-model="newAffiliate.organisationType" :options="['Business', 'Academic']" required :bootstrap-styling="true")
          b-form-group.col(label="Membership Starts *" label-for="starts")
            datepicker(name="starts" v-model="newAffiliate.createdDate" required :bootstrap-styling="true")
          b-form-group.col(label="Membership Ends *" label-for="ends")
            datepicker(name="ends" v-model="newAffiliate.validUntil" required :bootstrap-styling="true")

        b-row.mt-4(align-h="center")
          b-button(variant="success" size="lg" @click="createAffiliate(newAffiliate)" :disabled="!isFormValid") Create Affiliate
</template>

<script>
import Datepicker from 'vuejs-datepicker'

export default {
  components: {
    Datepicker
  },
  data: function () {
    return {
      newAffiliate: {
        createdDate: new Date(),
        validUntil: new Date(),
        organisationType: 'Business',
        name: '',
        memberIdPrefix: '',
        notes: '',
        contact: {
          name: '',
          email: ''
        }
      }
    }
  },
  computed: {
    isFormValid: function () {
      let result = true
      if (
        this.newAffiliate.name === '' ||
        this.newAffiliate.memberIdPrefix === '' ||
        this.newAffiliate.contact.name === '' ||
        this.newAffiliate.contact.email === ''
      ) {
        result = false
      }
      return result
    }
  },
  mounted: function () {
    // Fetch and prefill based on given id
    if (this.$route.query.dupe) {
      this.axios.get('/api/affiliates/' + this.$route.query.dupe)
        .then((res) => {
          this.newAffiliate.createdDate = new Date(res.data.validUntil)
          this.newAffiliate.validUntil = new Date(res.data.validUntil)
          this.newAffiliate.organisationType = res.data.organisationType
          this.newAffiliate.name = res.data.name
          this.newAffiliate.memberIdPrefix = res.data.memberIdPrefix
          this.newAffiliate.notes = res.data.notes
          this.newAffiliate.contact = res.data.contact

          this.addYearToValidUntil()
        })
        .catch(err => {
          this.$notify({
            group: 'error',
            type: 'error',
            title: 'Fetching affiliate data failed',
            text: err,
            duration: -1
          })
        })
    }
    else {
      this.addYearToValidUntil()
    }
  },
  methods: {
    createAffiliate: function (newAffiliate) {
      this.axios
        .post('/api/affiliates/new', newAffiliate)
        .then(res => {
          this.$notify({
            group: 'success',
            type: 'success',
            title: 'Affiliate created',
            text: res.data.name + ' is now in the club!'
          })

          this.$router.push({ path: '/affiliates' })
        })
        .catch(err => {
          this.$notify({
            group: 'error',
            type: 'error',
            title: 'Creating affiliate failed',
            text: err.response.data,
            duration: -1
          })
        })
    },
    addYearToValidUntil() {
      this.newAffiliate.validUntil.setFullYear(
        this.newAffiliate.validUntil.getFullYear() + 1,
        this.newAffiliate.validUntil.getMonth() + 1,
        0
      )
      this.newAffiliate.validUntil.setHours(23, 59, 59)
    }
  }
}
</script>
